import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Linking, Image, Alert, Modal, TextInput } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Feather from 'react-native-vector-icons/Feather';




const AccountSettingsPage = ({ navigation }) => {
  const [userData, setUserData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
const [accountAdminValue, setAccountAdminValue] = useState('');


const handleBackPress = () => {
  navigation.goBack(); // Use navigation to go back
};
  useEffect(() => {
    fetchUserData();
  }, []);

  const updateAccountAdmin = () => {
    fetch('https://collabbayadmin.com/APIControllers/UpdateAccountAdmin.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `AccountAdmin=${encodeURIComponent(accountAdminValue)}`,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
            
            alert('Admin succesfully added');
            window.location.href = 'https://collabbayadmin.com/'; // Redirect to external URL
        } else {
          alert('Something went wrong, reload the page and try again' || 'Failed to update Account Admin.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        Alert.alert('Error', 'Failed to update Account Admin.');
      });
  };
  


const [accountAdmin, setAccountAdmin] = useState('');

const fetchAccountAdmin = () => {
  fetch('https://collabbayadmin.com/APIControllers/GetVenueAccountAdmin.php')
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        setAccountAdmin(data.AccountAdmin);
      } else {
        console.error('Error fetching AccountAdmin:', data.message);
      }
    })
    .catch((error) => {
      console.error('Error fetching AccountAdmin:', error);
    });
};

useEffect(() => {
  fetchAccountAdmin();
}, []);


  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/EndSession.php');
      const data = await response.json();

      // Display logout status message
      if (data.success) {
        Alert.alert('Logout', 'Logout successful');
      } else {
        Alert.alert('Logout', 'No active session found');
      }

      // Reload the app after logout
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
    
    <View style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>

<View style={styles.backContainer}>
      <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
        <Ionicons name="arrow-back" size={24} color="black" />
      </TouchableOpacity>
    </View>

      {/* Profile Section */}
      <View style={styles.profileSection}>
        <Image
          style={styles.profilePicture}
          source={{ uri: userData.ProfileImage }}
          resizeMode="cover"
        />
        <View style={styles.profileDetails}>
          <View style={styles.emailContainer}>
            <Ionicons name="mail-outline" size={20} color="black" />
            <Text style={styles.profileText}>{userData.UserEmail}</Text>
          </View>
          <View style={styles.businessNameContainer}>
            <Ionicons name="business-outline" size={20} color="black" />
            <Text style={styles.profileText}>{userData.BusinessName}</Text>
          </View>
          <View style={styles.businessAddressContainer}>
            <Ionicons name="location-outline" size={20} color="black" />
            <Text style={styles.profileText}>{userData.BusinessAddress}</Text>
          </View>
        </View>
      </View>

      <TouchableOpacity
        style={styles.settingContainer}
        onPress={() => Linking.openURL('https://collabbayadmin.com/UpdateProfile.html')} // Redirects to the Edit Profile URL
      >
        <Ionicons name="person-outline" size={24} color="black" />
        <Text style={styles.settingText}>Edit Profile</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.settingContainer}>
        <Ionicons name="notifications-outline" size={24} color="black" />
        <Text style={styles.settingText}>Help</Text>
      </TouchableOpacity>

      <TouchableOpacity
    style={styles.settingContainer}
    onPress={() => Linking.openURL('https://collabbayadmin.com/plandetails')}
>
    <Ionicons name="card-outline" size={24} color="black" />
    <Text style={styles.settingText}>Billing</Text>
</TouchableOpacity>


<View>
<Text style={styles.venueTextMessage}>Your venue code is</Text>
<Text style={styles.venueText}>{userData.VenueCode}</Text>
</View>

      {/* Conditionally render the analytics button if user is an Admin */}
      {userData.Status === 'Admin' && (
        <TouchableOpacity
          style={styles.settingContainer}
          onPress={() => navigation.navigate('collabbayAnalytics')} // Redirects to the Analytics page
        >
          <Feather name="bar-chart" size={24} color="black" />
          <Text style={styles.settingText}>Analytics</Text>
        </TouchableOpacity>
      )}


<View style={styles.accountAdminContainer}>
  <Ionicons name="person-outline" size={24} color="black" style={styles.icon} />
  <View style={styles.cardTextContainer}>
    <Text style={styles.accountAdminLabel}>Account Admin</Text>
    {accountAdmin ? (
      accountAdmin.split(',').map((item, index) => (
        <Text key={index} style={styles.accountAdminValue}>
          {item.trim()}
        </Text>
      ))
    ) : (
      <Text style={styles.accountAdminValue}>None, add below</Text>
    )}
  </View>
</View>





<Modal
    animationType="slide"
    transparent={true}
    visible={modalVisible}
    onRequestClose={() => setModalVisible(false)}
>
    <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
        <Text style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold', marginBottom: 15 }}>
        Account admins, such as your reservations or confirmations team, are notified of approved bookings. Add their email as an account admin to keep them in the loop.
</Text>

<TextInput
  style={styles.input}
  placeholder="i.e. team@yourvenue.com"
  value={accountAdminValue}
  onChangeText={(text) => {
    // Remove spaces and update state
    const noSpacesText = text.replace(/\s/g, '');
    setAccountAdminValue(noSpacesText);
  }}
  onBlur={() => {
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(accountAdminValue)) {
      alert('Invalid Email', 'Please enter a valid email address.');
    }
  }}
/>

            <TouchableOpacity style={styles.saveButton} onPress={updateAccountAdmin}>
                <Text style={styles.saveButtonText}>Add Admin</Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={styles.cancelButton}
                onPress={() => setModalVisible(false)}
            >
                <Text style={styles.cancelButtonText}>X</Text>
            </TouchableOpacity>
        </View>
    </View>
</Modal>

<TouchableOpacity
  style={[styles.settingContainer, { marginTop: 10, justifyContent: 'center', alignItems: 'center' }]}
  onPress={() => setModalVisible(true)}
>
<Text style={[styles.settingText, { fontSize: 12, fontWeight: 'bold' }]}>
  Update or add an account admin
</Text>

</TouchableOpacity>



      <TouchableOpacity style={styles.homeButton} onPress={() => navigation.navigate('BusinessHomepage')}>
        <Feather name="home" size={24} color="#000" />
      </TouchableOpacity>

      <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
        <Text style={styles.logoutText}>Log Out</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
        <Text style={styles.linkText}>By using Collabbay, you agree to our Privacy Policy.</Text>
      </TouchableOpacity>
    </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 30,
    color: '#333333',
  },
  profileSection: {
    alignItems: 'center',
    marginBottom: 30,
  },
  profilePicture: {
    width: 150,
    height: 150,
    borderRadius: 75,
    borderWidth: 5,
    borderColor: '#fff',
  },
  profileDetails: {
    alignItems: 'center',
    marginTop: 10,
  },
  emailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  businessNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  businessAddressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  profileText: {
    marginLeft: 10,
    fontSize: 18,
    color: '#333333',
  },
  venueText: {
    fontSize: 57,
    color: '#333333',
    padding: 15,
    backgroundColo: '#f3f3f3',
    textAlign: 'center',
    borderRadius: 15, 
  },
  venueTextMessage: {
    fontSize: 12,
    color: '#333333',
    padding: 15,
    backgroundColo: '#f3f3f3',
    textAlign: 'center',
    borderRadius: 15,
  },
  settingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingHorizontal: 20,
    paddingVertical: 15,
    backgroundColor: '#f3f3f3',
    borderRadius: 15,
    width: '100%',
  },
  settingText: {
    marginLeft: 20,
    fontSize: 18,
    color: '#333333',
  },
  logoutButton: {
    backgroundColor: 'black',
    paddingVertical: 15,
    paddingHorizontal: 50,
    borderRadius: 15,
    marginTop: 30,
  },
  logoutText: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
  },
  homeButton: {},
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
    marginTop: 25,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
},
modalContent: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    width: '90%',
    alignItems: 'center',
},
modalHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
},
input: {
    borderWidth: 1,
    borderColor: '#ddd',
    width: '100%',
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
},
saveButton: {
    backgroundColor: 'black',
    padding: 10,
    borderRadius: 5,
    width: '100%',
    alignItems: 'center',
},
saveButtonText: {
    color: 'white',
    fontWeight: 'bold',
},
cancelButton: {
    marginTop: 10,
    width: '100%',
    alignItems: 'center',
},
cancelButtonText: {
    color: 'red',
    fontWeight: 'bold',
},accountAdminContainer: {
  flexDirection: 'row',
  alignItems: 'flex-start', // Align items to the top
  marginTop: 20,
  padding: 15,
  width: '79%', // Keep within 79% of the page width
  backgroundColor: '#f9f9f9',
  borderRadius: 10,
  elevation: 2,
  shadowColor: '#000',
  shadowOpacity: 0.1,
  shadowRadius: 5,
  shadowOffset: { width: 0, height: 2 },
  alignSelf: 'center',
},
accountAdminValue: {
  fontSize: 14,
  color: '#666',
  marginBottom: 5, // Add spacing between items
  lineHeight: 20, // Improve readability
},
cardTextContainer: {
  flex: 1, // Allow the text to take up the remaining space
},
accountAdminLabel: {
  fontSize: 16,
  fontWeight: 'bold',
  color: '#333',
  marginBottom: 10,
},
icon: {
  marginRight: 15,
  marginTop: 5, // Align with the text
},
cardTextContainer: {
  flex: 1,
},
accountAdminLabel: {
  fontSize: 16,
  fontWeight: 'bold',
  color: '#333',
},
accountAdminValue: {
  fontSize: 14,
  color: '#666',
},
backContainer: {
  alignItems: 'center',
  marginBottom: 20,
  marginTop: 0,
  padding: 20,
},

});

export default AccountSettingsPage;
