import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet, SafeAreaView, Linking, ActivityIndicator, TouchableOpacity, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { BarChart, PieChart } from 'react-native-chart-kit';
import { Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width;

const InfluencerAnalysis = ({ route, navigation }) => {
  const [profileData, setProfileData] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingAdditionalData, setLoadingAdditionalData] = useState(true);

  // Get PlatformUsername from the route params
  const { PlatformUsername } = route.params;

  useEffect(() => {
    // Fetch data using the PlatformUsername from route params
    fetch(`https://collabbayadmin.com/APIControllers/SocialProfileDataAPI.php?PlatformUsername=${PlatformUsername}`)
      .then(response => response.json())
      .then(data => {
        if (data && !data.error && data.instagramName) {
          setProfileData(data);
        } else {
          console.error('Unexpected API structure or error:', data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching Social Profile Data:', error);
        setLoading(false);
      });
  }, [PlatformUsername]);

  useEffect(() => {
    // Fetch additional influencer data
    fetch(`https://collabbayadmin.com/APIControllers/InfluencerDataProfile.php?username=${PlatformUsername}`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setAdditionalData(data);
        }
      })
      .catch(error => console.error('Error fetching Influencer Data Profile:', error))
      .finally(() => setLoadingAdditionalData(false));
  }, [PlatformUsername]);

  const handleBackPress = () => {
    navigation.goBack();
  };

  // Show loading symbol until both APIs have returned a response
  if (loading || loadingAdditionalData) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#000" />
          <Text style={styles.loadingText}>Analysing creator...</Text>
        </View>
      </SafeAreaView>
      </View>
      
    );
  }

  // Show fallback message if neither API returns data
  if (!profileData && !additionalData) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
      
      <SafeAreaView style={styles.safeArea}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>
        <View style={styles.noDataContainer}>
          <Text>No data available for this influencer.</Text>
        </View>
      </SafeAreaView>
      </View>
    );
  }

  const metrics = profileData
    ? [
        { label: 'Followers', value: profileData.followers.toLocaleString() },
        { label: 'Engagement Rate', value: `${(parseFloat(profileData.engagementRate) * 100).toFixed(2)}%` },
        { label: 'Avg Likes', value: Math.round(profileData.avgLikes).toLocaleString() },
        { label: 'Avg Comments', value: Math.round(profileData.avgComments).toLocaleString() },
      ]
    : [];

  const botPercentage = additionalData ? parseFloat(additionalData.BotsFakes) * 100 : 0;

  const pieData = additionalData
  ? [
      {
        name: `Bots/Fakes (${botPercentage.toFixed(2)}%)`, // Append percentage correctly
        population: botPercentage, // Use the exact percentage for the population
        color: '#ff8774',
        legendFontColor: '#000',
        legendFontSize: 12,
      },
      {
        name: `Other (${(100 - botPercentage).toFixed(2)}%)`, // Append remaining percentage
        population: 100 - botPercentage, // Use the remaining percentage for the population
        color: '#4CAF50',
        legendFontColor: '#000',
        legendFontSize: 12,
      },
    ]
  : [];

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  };
  

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
    <SafeAreaView style={styles.safeArea}>
      <ScrollView style={{ width: '90%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }}>

        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>


        {additionalData && additionalData.DOB && (
  <View style={styles.ageCard}>
    <Text style={styles.ageText}>{calculateAge(additionalData.DOB)}</Text>
    <Text style={styles.ageSubtext}>Years old</Text>
  </View>
)}

        {additionalData && additionalData.Interests && additionalData.ProfileImage && (
  <View style={styles.interestsCard}>
    <Image source={{ uri: additionalData.ProfileImage }} style={styles.interestsImage} />
    <Text style={styles.interestsText}>{additionalData.Interests}</Text>
  </View>
)}


        {profileData && (
          <>
            <Image source={{ uri: profileData.ProfileImage }} style={styles.profileImage} />
            <Text style={styles.name}>@{profileData.instagramName}</Text>
            <Text style={styles.description}>{profileData.description}</Text>

            {/* <View style={styles.themesContainer}> */}
              {/* <View style={styles.hashtags}>
                {profileData.hashtags.slice(0, 3).map((hashtag, index) => (
                  <Text key={index} style={styles.hashtag}>
                    {hashtag}
                  </Text>
                ))}
              </View> */}
            {/* </View> */}

            <View style={styles.metricsContainer}>
              {metrics.map((metric, index) => (
                <View key={index} style={styles.metricCard}>
                  <Text style={styles.metricValue}>{metric.value}</Text>
                  <Text style={styles.metricLabel}>{metric.label}</Text>
                </View>
              ))}
            </View>
          </>
        )}


{additionalData && additionalData.BotsFakes !== null && typeof additionalData.BotsFakes !== 'undefined' && (
  <View style={styles.pieChartContainer}>
    <Text style={styles.sectionTitle}>Fake Followers Analysis</Text>

    <Text style={{ fontSize: 9, padding: 4, marginTop: 3 }}>
      On average, for every 100 of this creator's followers we expect-
    </Text>

    <PieChart
      data={pieData}
      width={screenWidth - 30}
      height={220}
      chartConfig={{
        color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
      }}
      accessor="population"
      backgroundColor="transparent"
      paddingLeft="15"
      absolute
    />

    <Text style={styles.pieChartDescription}>
     A 25% fake follower range is an expected average for creators. You can use your own judgment when making metric-based decisions, but consider factors such as content aesthetic, total reach, and brand-alignment.
    </Text>
  </View>
)}



<TouchableOpacity
  style={{
    alignItems: 'center',
    padding: 15,
    borderRadius: 10,
    backgroundColor: '#FDF05D',
    marginTop: 40,
    marginBottom: 20, // Fixed typo in marginBottom
  }}
  onPress={() => Linking.openURL(`https://www.instagram.com/${PlatformUsername}`)} // Changed to PlatformUsername
>
  <Ionicons name="logo-instagram" size={24} color="#000" />
  <Text
    style={{
      fontWeight: 'bold',
      fontSize: 16,
      color: '#000',
      marginTop: 5,
    }}
  >
    Visit Instagram Profile
  </Text>
</TouchableOpacity>

      </ScrollView>
    </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  safeArea: { flex: 1, backgroundColor: '#FFF', width: '100%', maxWidth: 600, alignSelf: 'center',},
  container: { flexGrow: 1, alignItems: 'center', backgroundColor: '#FFF', padding: 15 },
  backButton: { position: 'absolute', top: 20, left: 20, zIndex: 1 },
  profileImage: { width: 120, height: 120, borderRadius: 60, alignSelf: 'center', marginBottom: 10, resizeMode: 'cover' },
  name: { fontSize: 24, fontWeight: 'bold', color: '#000', textAlign: 'center', marginBottom: 10 },
  description: { fontSize: 14, color: '#666', textAlign: 'center', marginBottom: 20 },
  metricsContainer: { flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', marginVertical: 20 },
  metricCard: { backgroundColor: '#FDF05D', width: '47.5%', borderRadius: 10, padding: 20, marginBottom: 15, alignItems: 'center' },
  metricValue: { fontSize: 20, fontWeight: 'bold', color: '#000' },
  metricLabel: { fontSize: 14, color: '#333', marginTop: 5 },
  pieChartContainer: { marginVertical: 20, alignItems: 'center' },
  pieChartDescription: { fontSize: 14, color: '#666', textAlign: 'center', paddingHorizontal: 20, marginTop: 10 },
  categoriesContainer: { marginVertical: 20, width: '100%' },
  category: { backgroundColor: '#DDD', paddingVertical: 5, paddingHorizontal: 10, borderRadius: 20, margin: 5, fontSize: 14, color: '#333' },
  loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  loadingText: { marginTop: 10, fontSize: 16, color: '#000' },
  interestsCard: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f3f3f3',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#f3f3f3',
    padding: 10,
    marginTop:15,
    marginVertical: 15,
  },
  interestsImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 15,
    resizeMode: 'cover',
  },
  interestsText: {
    fontSize: 14,
    fontWeight: '500',
    color: '#333',
    flexShrink: 1,
  },
  ageCard: {
    backgroundColor: '#fff',
    borderRadius: 20,
    paddingVertical: 9,
    marginTop:65,
    paddingHorizontal: 20,
    alignSelf: 'flex-end',
    marginVertical: 10,
    alignItems: 'center',
  },
  ageText: {
    fontSize: 31,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },
  ageSubtext: {
    fontSize: 14,
    color: '#666',
    textAlign: 'center',
    marginTop: 5,
  },
  
  
});

export default InfluencerAnalysis;
