import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, Modal, TouchableOpacity, Image, FlatList, TextInput, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import QRCode from 'react-native-qrcode-svg';

const OfferAssociatedCollabs = ({ route, navigation }) => {
  const { ListingID } = route.params;
  const [collabs, setCollabs] = useState([]);
  const [filteredCollabs, setFilteredCollabs] = useState([]);
  const [listingViews, setListingViews] = useState({});
  const [listingVisits, setListingVisits] = useState({});
  const [visitPercentage, setVisitPercentage] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [toggleStatus, setToggleStatus] = useState(''); // To hold the current status

  
  const [isModalVisible, setIsModalVisible] = useState(false); // Manage modal visibility
const [newToggleStatus, setNewToggleStatus] = useState(toggleStatus); // Holds the new selected status

useEffect(() => {
  fetch(`https://collabbayadmin.com/APIControllers/RetrieveListingAssociatedCollabs.php?ListingID=${ListingID}`)
    .then(response => response.json())
    .then(data => {
      setCollabs(data);
      setFilteredCollabs(data);
    })
    .catch(error => console.error('Error fetching collabs:', error));

  fetchListingViews();
  fetchListingVisits();
}, []);


useEffect(() => {
  fetchListingStatus(); // Fetch the listing status on component mount
}, []);

const openListing = () => {
  const url = `https://collabbay.com/collaboffer/${ListingID}`;
  Linking.openURL(url);
};

const fetchListingStatus = () => {
  fetch(`https://collabbayadmin.com/APIControllers/GetCurrentPauseLiveStatus.php?ListingID=${ListingID}`)
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") {
        setToggleStatus(data.PauseLiveToggle);  // Set the current status ('Live' or 'Paused')
      } else {
        console.error('Error fetching listing status:', data.message);
      }
    })
    .catch(error => console.error('Error fetching listing status:', error));
};

const handleSubmitStatusChange = () => {
  const url = `https://collabbayadmin.com/APIControllers/PauseActivateListing.php`;

  fetch(url, {
      method: 'POST', // Ensure you're sending a POST request
      headers: {
          'Content-Type': 'application/json', // Set the correct content type
      },
      body: JSON.stringify({  // Send data as JSON
          'ListingID': ListingID,
          'PauseLiveStatusValue': newToggleStatus, // Use newToggleStatus instead of selectedStatus
      }),
  })
  .then(response => response.json())
  .then(data => {
      if (data.status === "success") {
          setToggleStatus(newToggleStatus); // Update status in the UI
          setIsModalVisible(false); // Close the modal

          // Show alert to the user

          // Reload the page after successful submission
          window.location.reload();
      } else {
          console.error('Failed to update status:', data.message);
      }
  })
  .catch(error => console.error('Error updating status:', error));
};

const getStatusWithDot = (status) => {
  let color = 'grey'; // Default to grey if no status matches

  if (status === 'Live') {
    color = 'green';
  } else if (status === 'Pause') {
    color = 'red';
  }

  return (
    <View style={{ flexDirection: 'row', marginTop: 12, marginBottom: 3 ,alignItems: 'center', justifyContent: 'center' }}>
      <Text style={{ fontSize: 12 }}>Current Status | {status}</Text>
      <View
        style={{
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: color,
          marginLeft: 5, // Space between the text and the dot
        }}
      />
    </View>
  );
};


  

  useEffect(() => {
    if (listingViews.totalViews && listingVisits.totalCount) {
      const percentage = (listingVisits.totalCount / listingViews.totalViews) * 100;
      setVisitPercentage(percentage.toFixed(2));
    }
  }, [listingViews.totalViews, listingVisits.totalCount]);

  useEffect(() => {
    if (searchText) {
      setFilteredCollabs(
        collabs.filter(collab =>
          collab.Promoter.toLowerCase().includes(searchText.toLowerCase()) ||
          collab.AdHeader.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setFilteredCollabs(collabs);
    }
  }, [searchText, collabs]);

  const fetchListingViews = () => {
    fetch(`https://collabbayadmin.com/APIControllers/RetrieveListingViews.php?ListingID=${ListingID}`)
      .then(response => response.json())
      .then(data => setListingViews(data))
      .catch(error => console.error('Error fetching listing views:', error));
  };

  const fetchListingVisits = () => {
    fetch(`https://collabbayadmin.com/APIControllers/RetrieveListingConversions.php?ListingID=${ListingID}`)
      .then(response => response.json())
      .then(data => setListingVisits(data))
      .catch(error => console.error('Error fetching listing visits:', error));
  };

  const getCollabStatus = (collab) => {
    // Check if the status is "Cancelled" first
    if (collab.Status === 'Cancelled') {
      return 'Cancelled';
    }
  
    const today = new Date();
    const creditAvailableDate = new Date(collab.CreditAvailableStart);
  
    // Check if the credit availability date is in the past
    if (creditAvailableDate < today) {
      if (collab.Redeemed) {
        return 'Redeemed';
      } else {
        return 'Expired';
      }
    } else {
      // Check for other statuses
      if (collab.Status === 'Accepted') {
        return 'Upcoming';
      } else if (collab.Status === 'Pending') {
        return 'Pending';
      }
    }
  
    // Return null if no status matches
    return null;
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Expired':
        return styles.expiredStatus;
      case 'Redeemed':
        return styles.redeemedStatus;
      case 'Upcoming':
        return styles.upcomingStatus;
      case 'Pending':
        return styles.pendingStatus;
      case 'Cancelled':
        return styles.cancelledStatus; // Add this case for Cancelled
      default:
        return null;
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Expired':
        return <Ionicons name="time" size={16} color="black" />;
      case 'Redeemed':
        return <Ionicons name="checkmark-circle" size={16} color="black" />;
      case 'Upcoming':
        return <Ionicons name="calendar" size={16} color="black" />;
      case 'Pending':
        return <Ionicons name="alert-circle" size={16} color="black" />;
      default:
        return null; 
    }
  };


  const renderCollabItem = ({ item }) => {
    const collabStatus = getCollabStatus(item);

    return (
      <TouchableOpacity style={styles.collabsCard} onPress={() => navigation.navigate('SingleCollabMetrics', { CollabID: item.CollabID })}>
        <Text style={[styles.collabsCardIDText, { fontSize: 7 }]}>ID {item.CollabID}</Text>

        <Text style={[styles.collabsCardIDTextPromoter, { fontSize: 17 }]}>
  {item.Promoter} requested on {(() => {
    const date = new Date(item.Date);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const suffix =
      day % 10 === 1 && day !== 11 ? 'st' :
      day % 10 === 2 && day !== 12 ? 'nd' :
      day % 10 === 3 && day !== 13 ? 'rd' : 'th';
    return `${day}${suffix} ${month}`;
  })()}
</Text>

        <Text style={styles.collabsCardIDText}>for {item.CreditAvailableStart}</Text>
        {/* <Text style={styles.collabsCardIDText}>Offer: {item.NonCreditOffer}</Text> */}

        {/* Status Indicator */}
        {collabStatus && (
          <View style={[styles.statusBadge, getStatusStyle(collabStatus)]}>
            {getStatusIcon(collabStatus)}
            <Text style={styles.statusText}>{collabStatus}</Text>
          </View>
        )}
      </TouchableOpacity>
    );
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
    <SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>

      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>
        
        <Text style={styles.idText}>Offer ID {ListingID}</Text>
  


         <TouchableOpacity
  style={styles.navigateButton}
  onPress={() => navigation.navigate('EditOfferListing', { ListingID })}
>
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    <Ionicons name="create-outline" size={10} color="black" />
    <Text style={styles.navigateButtonText}>Edit This Offer</Text>
  </View>
</TouchableOpacity>



      </View>

      {/* QR Code Section */}
      {/* <View style={styles.qrCodeContainer}>
        <Image source={{ uri: 'https://collabbayadmin.com/CollabbayLogoDark.png' }} style={styles.logo} />
        <QRCode
          value={`https://collabbay.com/collaboffer/${ListingID}`} 
          size={250} 
        />
        <Text style={styles.ctaqrText}>Present, share or print to allow users to collab.</Text>

        <TouchableOpacity 
          style={styles.savePrintButton} 
          onPress={() => Linking.openURL(`https://collabbayadmin.com/ListingQRPDFImage.html?ListingID=${ListingID}`)}
        >
          <Ionicons name="download" size={16} color="white" />
          <Text style={styles.savePrintButtonText}> Save and Print QR</Text>
        </TouchableOpacity>
      </View> */}

<View style={{ alignItems: 'center', marginTop: 5, marginBottom: 5 }}>
  <Text
    style={{
      color: 'black',
      textDecorationLine: 'none', // No underline
      backgroundColor: '#fdf05d',
      padding: 7,
      borderRadius: 15,
      textAlign: 'center',
      fontWeight: 'bold',
      width: '70%',
    }}
    onPress={openListing}
  >
    <Ionicons name="eye-outline" size={16} color="black" /> View Offer Listing
  </Text>
</View>


<TouchableOpacity
  style={[styles.pauseActivateButton, { marginTop: 12 }]}
  onPress={() => setIsModalVisible(true)}
>
  <Ionicons name="power" size={16} color="black" />
  <Text style={styles.pauseActivateButtonText}>Pause or activate this offer listing</Text>
</TouchableOpacity>


<TouchableOpacity
      style={styles.footerIcon}
      onPress={() => navigation.navigate('CampaignMetrics', { ListingID })}
    >
      <View style={{ alignItems: 'center' }}>
        {/* Analytics icon */}
        <Ionicons name="analytics-outline" size={24} color="black" />
        
        {/* Centered text under the icon */}
        <Text style={{ marginBottom: 7,marginTop: 4, fontSize: 12, color: 'black' }}>View Campaign Metrics</Text>
      </View>
    </TouchableOpacity>



      <Modal
  animationType="slide"
  transparent={true}
  visible={isModalVisible}
  onRequestClose={() => {
    setIsModalVisible(!isModalVisible);
  }}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <TouchableOpacity
        style={styles.closeButton}
        onPress={() => setIsModalVisible(false)}
      >
        <Ionicons name="close" size={24} color="black" />
      </TouchableOpacity>
      
      <Text style={styles.modalTitle}>Change Offer Status</Text>
      <Text style={styles.disclaimer}>
        Your collab offer will be paused and not visible on the platform until you reactivate it. Any collabs agreed prior to you pausing this offer will still be active unless cancelled.
      </Text>

      <View style={styles.buttonRow}>
        <TouchableOpacity
          style={[styles.toggleButton, newToggleStatus === 'Live' && styles.selectedButton]}
          onPress={() => setNewToggleStatus('Live')}
        >
          <Text style={styles.toggleButtonText}>Live</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.toggleButton, newToggleStatus === 'Pause' && styles.selectedButton]}
          onPress={() => setNewToggleStatus('Pause')}
        >
          <Text style={styles.toggleButtonText}>Pause</Text>
        </TouchableOpacity>
      </View>

      <TouchableOpacity
  style={styles.confirmButton}
  onPress={handleSubmitStatusChange} // Call the correct function here
>
  <Text style={styles.confirmButtonText}>Confirm</Text>
</TouchableOpacity>
    </View>
  </View>
</Modal>



      {/* <View style={styles.analyticsContainer}>
        <View style={styles.analyticsCard}>
          <Text style={styles.analyticsTitle}>Views</Text>
         
<Text style={styles.whiteText}>Today: {listingViews.viewsToday}</Text>
<Text style={styles.whiteText}>Last 7 Days: {listingViews.viewsLast7Days}</Text>
<Text style={styles.whiteText}>Last 30 Days: {listingViews.viewsLast30Days}</Text>
        </View>

        <View style={styles.analyticsCard}>
          <Text style={styles.analyticsTitle}>Visits</Text>
          <Text style={{ color: 'white' }}>Today: {listingVisits.countToday}</Text>
<Text style={{ color: 'white' }}>Last 7 Days: {listingVisits.countLast7Days}</Text>
<Text style={{ color: 'white' }}>Last 30 Days: {listingVisits.countLasst30Days}</Text>
        </View>
      </View> */}

      <View style={styles.searchContainer}>
        <Ionicons name="search" size={24} color="#999" style={styles.searchIcon} />
        <TextInput
          style={styles.searchInput}
          placeholder="Find a collab"
          value={searchText}
          onChangeText={setSearchText}
        />
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {`${filteredCollabs.length} Collab Requests`}
        </Text>
        <FlatList
          data={filteredCollabs}
          renderItem={renderCollabItem}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('QRCodeScannerPage')}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessAccountPage')}>
          <Ionicons name="person" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessHomepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />          
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CreateBrandListingChoice')}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BrandListingsDashboard')}>
          <Ionicons name="laptop" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  idText: {
    fontSize: 12,
    padding: 5,
  },
  analyticsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10,
    color: 'white',
  },
  analyticsCard: {
    flex: 1,
    backgroundColor: '#000',
    borderRadius: 10,
    padding: 10,
    marginHorizontal: 11,
  },
  analyticsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#FDF05D',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: '#f0f0f0',
    padding: 10,
    marginHorizontal: 10,
    marginVertical: 5,
  },
  searchIcon: {
    marginRight: 10,
  },
  searchInput: {
    flex: 1,
    fontSize: 16,
  },
  section: {
    flex: 1,
    paddingHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: 'black',
  },
  collabsCard: {
    backgroundColor: '#fff',
    borderRadius: 10,
    borderColor: '#ddd',
    borderWidth: 1,
    padding: 10,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    position: 'relative',
  },
  collabsCardIDText: {
    fontSize: 14,
    padding: 5,
    color: 'black',
  },
  collabsCardIDTextPromoter: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: 5,
    marginTop: 16,
    color: 'black',
  },
  qrCodeContainer: {
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  logo: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
    marginBottom: 10,
    borderRadius: 10,
  },
  ctaqrText: {
    fontSize: 14,
    padding: 5,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    width: '80%',
  },
  savePrintButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#000', // Button color
    borderRadius: 20,
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: 10,
  },
  savePrintButtonText: {
    color: 'white', // Text color
    marginLeft: 5,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    paddingVertical: 10,
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,

  },
  footerIcon: {
    alignItems: 'center',
  },
  toggleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  statusBadge: {
    position: 'absolute',
    top: 10,
    right: 10,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
    marginBottom: 10,
    color: 'black',
  },
  statusText: {
    color: 'black',
    marginLeft: 5,
    fontSize: 12,
  },
  expiredStatus: {
    backgroundColor: 'grey',
  },
  redeemedStatus: {
    backgroundColor: 'darkgreen',
  },
  upcomingStatus: {
    backgroundColor: 'lightgreen',
  },
  pendingStatus: {
    backgroundColor: 'yellow',
  },
  campaignMetricsButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ecece7',
    borderRadius: 20,
    marginBottom: 15,
    width: '80%',
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: 10,
    alignSelf: 'center',
  },
  campaignMetricsButtonText: {
    color: 'black',
    marginLeft: 5,
  },
  cancelledStatus: {
    backgroundColor: 'red', // Background color for "Cancelled"
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  },
  statusText: {
    color: 'black', // Ensures the text is visible on the red background
    fontSize: 12,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent background
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    width: '80%',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
    color: 'black',
  },
  disclaimer: {
    fontSize: 12,
    color: 'black',
    textAlign: 'center',
    marginBottom: 20,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  toggleButton: {
    flex: 1,
    backgroundColor: '#f0f0f0',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  selectedButton: {
    backgroundColor: 'black',
  },
  toggleButtonText: {
    color: 'black',
    fontWeight: 'bold',
  },
  confirmButton: {
    marginTop: 20,
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  confirmButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  pauseActivateButton: {
    flexDirection: 'row', // Arrange the icon and text in a row
    alignItems: 'center', // Center the items vertically
    backgroundColor: '#ECECE7', // Modern button color (blue)
    paddingVertical: 8, // Smaller padding to make the button compact
    paddingHorizontal: 16, // Padding for width control
    borderRadius: 30, // Rounded button
    alignSelf: 'center', // Center the button horizontally
    marginBottom: 10, // Space below the button
  },
  pauseActivateButtonText: {
    color: 'black', // White text for visibility
    fontSize: 12, // Slightly smaller font size
    marginLeft: 8, // Space between the icon and the text
  },
  navigateButton: {
    backgroundColor: '#f3f3f3', // Button color
    paddingVertical: 10, // Vertical padding
    paddingHorizontal: 20, // Horizontal padding
    borderRadius: 20, // Rounded corners
    alignItems: 'right', // Center the content
    justifyContent: 'right', // Center the content
    marginVertical: 10, // Spacing from other elements
    alignSelf: 'right', // Center the button horizontally
  },
  navigateButtonText: {
    color: 'black', // Text color
    fontSize: 10, // Font size
    fontWeight: 'bold', // Bold text
    marginLeft: 8, // Space between icon and text
  },
  
});

export default OfferAssociatedCollabs;
