import React, { useState, useEffect } from 'react';
import { ActivityIndicator, SafeAreaView, StyleSheet, View, Text, ScrollView, Linking, Image, TextInput, TouchableOpacity, Modal } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { differenceInCalendarDays } from 'date-fns';

const PotentialCollabsDashboardView = ({ navigation }) => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userData, setUserData] = useState({});
  const [noOffersMessage, setNoOffersMessage] = useState(''); // New state for no offers message

  const [adminRAGData, setAdminRAGData] = useState([]);
  const [loadingRAG, setLoadingRAG] = useState(true);

  const [loading, setLoading] = useState(true); // Define loading state

useEffect(() => {
  const fetchAllData = async () => {
    await Promise.all([fetchData(), fetchUserData(), fetchAdminRAGData()]);
    setLoading(false); // Set loading to false after data is fetched
  };
  fetchAllData();
}, []);

  const fetchAdminRAGData = async () => {
    try {
      setLoadingRAG(true);
      const response = await fetch('https://collabbayadmin.com/APIControllers/BrandGetContentRAG.php');
      const data = await response.json();
  
      if (data.success) {
        // Filter only YELLOW and RED collabs
        const filteredData = data.results.filter(
          (collab) => collab.Status === 'YELLOW' || collab.Status === 'RED'
        );
        setAdminRAGData(filteredData);
      } else {
        setAdminRAGData([]);
      }
    } catch (error) {
      console.error('Error fetching Admin RAG data:', error);
      setAdminRAGData([]);
    } finally {
      setLoadingRAG(false);
    }
  };
  
  useEffect(() => {
    fetchAdminRAGData();
  }, []);

  useEffect(() => {
    fetchData();
    fetchUserData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/RetrieveBrandListings.php');
      const jsonData = await response.json();

      if (jsonData.message) {
        // Check if the response contains the no offers message
        setNoOffersMessage(jsonData.message);
        setData([]); // Clear any existing data
      } else if (Array.isArray(jsonData)) {
        setData(jsonData);
        setNoOffersMessage(''); // Clear the no offers message if data is received
      } else {
        setData([]); // Handle unexpected response format
        setNoOffersMessage(''); // Clear the no offers message
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      setNoOffersMessage('Error fetching data'); // Handle fetch error
    }
  };

  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => setUserData(data))
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const handleBackPress = () => {
    navigation.goBack();
  };

  const handleSearchChange = (text) => {
    setSearchQuery(text);
  };

  const filteredData = data.filter(item =>
    item.AdHeader.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const suffix = day % 10 === 1 && day !== 11 ? 'st' :
                   day % 10 === 2 && day !== 12 ? 'nd' :
                   day % 10 === 3 && day !== 13 ? 'rd' : 'th';

    const formattedDate = `${day}${suffix} ${month}`;
    return year !== now.getFullYear() ? `${formattedDate}, ${year}` : formattedDate;
  };


  if (loading) {
    return (
      
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
        <ActivityIndicator size="large" color="black" />
      </View>
    );
  }

  return (
    <View style={{ flex: 1, width: '100%', backgroundColor: '#fff' }}>
    <SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>
      <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
        <Ionicons name="arrow-back" size={24} color="black" />
      </TouchableOpacity>

      <View style={styles.searchBar}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search my campaigns"
          placeholderTextColor="grey"
          value={searchQuery}
          onChangeText={handleSearchChange}
        />
      </View>

      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View style={styles.section}>
          <View style={styles.sectionTitleContainer}>
            <Text style={styles.sectionTitle}>My Offers</Text>
          </View>

          {noOffersMessage ? (
            <Text style={styles.noOffersText}>{noOffersMessage}</Text>
          ) : filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <TouchableOpacity 
                style={styles.card} 
                key={index} 
                onPress={() => navigation.navigate('ListingAssociatedCollabsDashboard', { ListingID: item.ListingID })}
              >
                <View style={styles.cardContent}>
                <Image
    style={styles.cardLogo}
    source={{ uri: item.LogoImage || 'https://collabbayadmin.com/NoListingImage.jpg' }}
/>


                  <TouchableOpacity
          style={styles.editIconContainer}
          onPress={() => {
            const listingID = item.ListingID;
            const url = `https://collabbayadmin.com/ListingImageUpload.html?ListingID=${encodeURIComponent(listingID)}`;
            Linking.openURL(url); // Open URL using Linking
          }}
        >
          <Ionicons name="create-outline" size={20} color="black" />
        </TouchableOpacity>

                  <View style={styles.cardTextContainer}>
                    <Text style={styles.cardSubText}>Created {formatDate(item.Created_At)}</Text>
                    <Text style={styles.cardTitle}>{item.AdHeader}</Text>
                    <Text style={styles.cardSubText}>
  {item.ListingDescription.length > 100
    ? `${item.ListingDescription.substring(0, 100)}...`
    : item.ListingDescription}
</Text>
                    <Text style={styles.cardSubLocationText}>{item.Location}</Text>
                    <View>
  {item.PauseLiveToggle === 'Live' ? (
    <View style={styles.liveCard}>
      <Text style={styles.liveText}>Live</Text>
      <View style={styles.liveIndicator} />
    </View>
  ) : (
    <View style={styles.pausedCard}>
      <Text style={styles.pausedText}>Paused</Text>
    </View>
  )}
</View>

                  </View>
                </View>
              </TouchableOpacity>
            ))
          ) : (
            <Text style={styles.noOffersText}>No collab offers yet, click + to create one</Text>
          )}
        </View>
      </ScrollView>

<View style={styles.section}>

  {loadingRAG ? (
    <ActivityIndicator size="large" color="black" style={styles.spinner} />
  ) : adminRAGData.length === 0 ? (
    <Text style={styles.noOffersText}></Text>
  ) : (
    adminRAGData.map((collab, index) => {
      const dueDate = new Date(collab.LatestDeliveryDate);
      const today = new Date();
      const daysLeft = differenceInCalendarDays(dueDate, today);

      return (
        <TouchableOpacity
          key={index}
          style={{
            borderRadius: 10,
            marginTop: 15,
            borderWidth: 2,
            width: '90%',
            borderColor: collab.Status === 'YELLOW' ? '#f2c94c' : '#eb5757',
            backgroundColor: '#f9f9f9',
            padding: 15,
            alignSelf: 'center',
          }}
        >
          <View style={{ alignItems: 'flex-start' }}>
            <Ionicons name="time-outline" size={24} color="black" style={{ marginBottom: 5 }} />
            <Text style={{ fontSize: 20, fontWeight: 'bold', color: '#000', marginBottom: 5 }}>
              {collab.Status === 'YELLOW'
                ? `Pending content, due in ${daysLeft} days`
                : 'Content overdue.'}
            </Text>
            <Text style={{ fontSize: 16, color: '#555' }}>from {collab.Promoter}</Text>
          </View>
        </TouchableOpacity>
      );
    })
  )}
</View>

 
      <View style={styles.footer}>

        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CreateBrandListingChoice')}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>

      </View>
    </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  searchBar: {
    marginTop: 70, // Adjust based on your needs
    paddingHorizontal: 20,
  },
  searchInput: {
    backgroundColor: '#e0e0e0',
    color: 'black',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 20,
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    width: '100%',
    alignSelf: 'center',
    marginTop: 20,
  },
  section: {
    marginBottom: 20,
    width: '100%',
  },
  sectionTitleContainer: {
    backgroundColor: '#FDF05D',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
  },
  card: {
    width: '100%',
    minWidth: '100%',
    backgroundColor: '#f3f3f3',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 3,
  },
  cardContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardLogo: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 15,
    resizeMode: 'cover',
  },
  cardTextContainer: {
    flex: 1,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'black',
  },
  cardSubText: {
    fontSize: 12,
    color: 'black',
    padding: 5,
  },
  cardSubLocationText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
    marginBottom: 11,
  },
  noOffersText: {
    fontSize: 16,
    color: 'grey',
    textAlign: 'center',
    marginVertical: 20,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  editIconContainer: {
    alignItems: 'center',
    marginTop: 10,  // Adjust this value based on your design needs
  },
  spinner: {
  marginVertical: 10,
  alignSelf: 'center',
  color: 'black',
},
sectionTitleContainer: {
  backgroundColor: '#FDF05D',
  borderRadius: 10,
  paddingHorizontal: 10,
  paddingVertical: 5,
  marginBottom: 10,
},
sectionTitle: {
  fontSize: 20,
  fontWeight: 'bold',
  color: 'black',
},liveCard: {
  backgroundColor: '#DFF6DD',
  borderRadius: 10,
  padding: 5,
  borderWidth: 1,
  borderColor: '#32CD32',
  flexDirection: 'row',
  alignItems: 'center',
  width: '25%',               // Set width to 25%
  justifyContent: 'center',   // Centre the content
  alignSelf: 'flex-end',      // Float to the right
},
pausedCard: {
  backgroundColor: '#333',
  borderRadius: 10,
  padding: 5,
  borderWidth: 1,
  borderColor: '#444',
  alignItems: 'center',
  width: '25%',               // Set width to 25%
  justifyContent: 'center',   // Centre the content
  alignSelf: 'flex-end',      // Float to the right
},
liveText: {
  fontWeight: 'bold',
  color: '#32CD32',
},
pausedText: {
  fontWeight: 'bold',
  color: 'white',
},
liveIndicator: {
  width: 8,
  height: 8,
  borderRadius: 4,
  backgroundColor: '#32CD32',
  marginLeft: 5,
},


});

export default PotentialCollabsDashboardView;
