import React, { useState, useEffect } from 'react';
import { SafeAreaView, Image, StyleSheet, View, Text, TouchableOpacity, Clipboard, ScrollView, TextInput, Button, Alert, Modal, Picker, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import QRCode from 'react-native-qrcode-svg';


const CollabCampaignDetails = ({ route, navigation }) => {
  const { CollabID } = route.params;
  const [secretQRID, setSecretQRID] = useState('');
  const [ipCount, setIpCount] = useState(null);
  const [tierData, setTierData] = useState(null);
  const [redeemableTier, setRedeemableTier] = useState('None');
  const [redeemableValue, setRedeemableValue] = useState(0);
  const [redeemableOffer, setRedeemableOffer] = useState(0);
  const [userData, setUserData] = useState({});
  const [promoterEmail, setPromoterEmail] = useState(null); 
  const [promoterName, setPromoterName] = useState(null);
  const [message, setMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [latestUpdate, setLatestUpdate] = useState({ Message: '', CreditAvailableStart: '', Status: '' });
  const [mediaContent, setMediaContent] = useState([]);
  const [apiMessages, setApiMessages] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [platformUsername, setPlatformUsername] = useState('');
  const [platformFollowers, setPlatformFollowers] = useState('');
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [redeemDateTime, setRedeemDateTime] = useState(null);
  const [selectedTime, setSelectedTime] = useState('7:00 AM');

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [cancelMessage, setCancelMessage] = useState('');

  const [isExpanded, setIsExpanded] = useState(false);


const [accountAdmin, setAccountAdmin] = useState('');

const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
const [updateModalMessage, setUpdateModalMessage] = useState('');
const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);


const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
const [approveModalMessage, setApproveModalMessage] = useState('');
const [isApproveSuccessful, setIsApproveSuccessful] = useState(false);



const [isCancelSuccessModalVisible, setIsCancelSuccessModalVisible] = useState(false);
const [cancelSuccessMessage, setCancelSuccessMessage] = useState('');

const [isEmailCopied, setIsEmailCopied] = useState(false);

const handleCopyEmail = () => {
  if (promoterEmail) {
    Clipboard.setString(promoterEmail);
    setIsEmailCopied(true);

    // Reset the icon after 2 seconds
    setTimeout(() => setIsEmailCopied(false), 2000);
  } else {
    alert('No email address to copy.');
  }
};

const handleToggleExpand = () => {
  setIsExpanded(!isExpanded);
};

const [activeTab, setActiveTab] = useState('CodeSystem'); // Default tab is "Code System"



  useEffect(() => {
    fetchSecretQRID();
    fetchNewApi();
    fetchUserData();
    fetchPromoterDetails(); // Updated function name
    fetchLatestCollabUpdate();
    fetchMediaContent();
    fetchUserMediaValidation();
    fetchBrandApprovalValidation();
    checkRedeemStatus(); 
    
  }, []);


  const [isHowToCollabVisible, setIsHowToCollabVisible] = useState(false);


  const toggleHowToCollabVisibility = () => {
    setIsHowToCollabVisible((prevState) => !prevState);
  };
  

  
  const fetchAccountAdmin = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetVenueAccountAdmin.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setAccountAdmin(data.AccountAdmin); // Set the fetched value
        } else {
          console.error('Error fetching AccountAdmin:', data.message);
        }
      })
      .catch((error) => console.error('Error:', error));
  };
  
  useEffect(() => {
    fetchAccountAdmin();
  }, []);
  

  const fetchPromoterDetails = () => {
    fetch(`https://collabbayadmin.com/APIControllers/GetCollabPromoterName.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data && data[0] && data[0].Promoter) {
          const fetchedPromoter = data[0].Promoter;
          setPromoterName(fetchedPromoter);
          fetchPromoterEmail(fetchedPromoter);
          fetchPromoterProfile(fetchedPromoter); // Fetch the promoter's profile details
        } else {
          console.error('Promoter name not found');
        }
      })
      .catch(error => console.error('Error fetching promoter name:', error));
  };
  

  
  const fetchPromoterProfile = (promoterName) => {
    fetch(`https://collabbayadmin.com/APIControllers/ShowPromoterProfile.php?Promoter=${promoterName}`)
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data) && data.length > 0) {
          const profileData = data[0];
          setProfileImage(profileData.ProfileImage);
          setPlatformUsername(profileData.PlatformUsername);  // Set platform username dynamically
          setPlatformFollowers(profileData.PlatformFollowers);
        } else {
          console.error('Promoter profile data not found');
        }
      })
      .catch(error => console.error('Error fetching promoter profile:', error));
  };
  

  const handleCancelBooking = () => {
    const fullDateTime = new Date().toISOString();
  
    fetch(`https://collabbayadmin.com/APIControllers/UserCancelCollab.php?CollabID=${CollabID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Message: cancelMessage,
        CreditAvailableStart: fullDateTime,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setCancelSuccessMessage('Cancellation Sent');
          setIsCancelSuccessModalVisible(true); // Show success modal
          setIsCancelModalVisible(false);       // Hide cancellation input modal
        } else {
          setCancelSuccessMessage('There was an error processing your cancellation.');
          setIsCancelSuccessModalVisible(true);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setCancelSuccessMessage('An unexpected error occurred.');
        setIsCancelSuccessModalVisible(true);
      });
  };
  
  
  const toggleCancelModal = () => {
    setIsCancelModalVisible(!isCancelModalVisible);
  };
  

  const fetchPromoterEmail = (promoterName) => { // Updated function
    fetch(`https://collabbayadmin.com/APIControllers/GetCollabPromoterEmail.php?Promoter=${promoterName}`)
      .then(response => response.json())
      .then(data => {
        if (data && data[0] && data[0].UserEmail) {
          setPromoterEmail(data[0].UserEmail);
        } else {
          console.error('Promoter email not found');
        }
      })
      .catch(error => console.error('Error fetching promoter email:', error));
  };




  const getCollabStatus = (collab) => {
    const today = new Date();
    const creditAvailableDate = new Date(collab.CreditAvailableStart);
  
    // If redeemed, prioritize the redeemed status
    if (isRedeemed) {
      return 'Redeemed';
    }
  
    if (collab.Status === 'Cancelled') {
      return 'Cancelled';
    }
  
    if (creditAvailableDate < today) {
      return 'Expired';
    }
  
    return collab.Status === 'Accepted' ? 'Upcoming' : 'Pending';
  };
  

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Redeemed':
        return styles.redeemedStatus;
      case 'Expired':
        return styles.expiredStatus;
      case 'Upcoming':
        return styles.upcomingStatus;
      case 'Pending':
        return styles.pendingStatus;
      case 'Cancelled':
        return styles.cancelledStatus;
      default:
        return null;
    }
  };


  const fetchSecretQRID = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveQRSecretForScan.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data.SecretQRID) {
          setSecretQRID(data.SecretQRID);
        } else {
          console.error('SecretQRID not found');
        }
      })
      .catch(error => console.error('Error fetching SecretQRID:', error));
  };

  const fetchNewApi = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveCollabViewCount.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(responseData => {
        setIpCount(parseInt(responseData.ipCount));
        fetchTierAssignApi();
      })
      .catch(error => {
        console.error('Error fetching IP count:', error);
      });
  };

  const fetchTierAssignApi = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveCollabTierData.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(responseData => {
        setTierData(responseData);
      })
      .catch(error => {
        console.error('Error fetching tier data:', error);
      });  
  };

  const fetchUserData = () => {
    fetch(`https://collabbayadmin.com/APIControllers/GetSessionData.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched User Data:', data); // Log API response
        if (data && data.Username) {  // Ensure Username exists
          setUserData(data);  // Set user data in state
        } else {
          console.error('Username not found in response');
        }
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  };
  

  const fetchLatestCollabUpdate = () => {
    fetch(`https://collabbay.com/APIControllers/GetLatestCollabUpdate.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setLatestUpdate({
            Message: data.Message || '',
            CreditAvailableStart: data.CreditAvailableStart || '',
            Status: data.Status || '',
            Credits: data.Credits || '',
            BrandName: data.BrandName || '',
            AddedVisitors: data.AddedVisitors || 0,
            LastUpdateFrom: data.LastUpdateFrom || '',
            CreatorFirstName: data.CreatorFirstName || '',  // New field
            CreatorLastName: data.CreatorLastName || '',    // New field
            CreatorPhoneNumber: data.CreatorPhoneNumber || '' // New field
          });
        } else {
          console.error('Latest collab update not found');
        }
      })
      .catch(error => {
        console.error('Error fetching latest collab update:', error);
      });
  };
  

  const fetchMediaContent = () => {
    fetch(`https://collabbayadmin.com/APIControllers/GetCollabMedia.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setMediaContent(data);
        } else {
          console.log('No media content found');F
        }
      })
      .catch(error => {
        console.error('Error fetching media content:', error);
      });
  };
  
  const fetchUserMediaValidation = async () => {
    try {
        const response = await fetch(`https://collabbayadmin.com/APIControllers/ErrorCheckUserMedia.php?CollabID=${CollabID}`);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const result = await response.json(); // Parse as JSON

        const message = result.message === 'Not Validated' 
            ? "Warning, the creators content is pending, to be uploaded after their visit" 
            : "User content uploaded";
        
        setApiMessages(prev => [...prev, message]);
    } catch (error) {
        console.error('Error fetching user media validation:', error);
    }
};




  const fetchBrandApprovalValidation = async () => {
    try {
        const response = await fetch(`https://collabbayadmin.com/APIControllers/ErrorCheckBrandApproval.php?CollabID=${CollabID}`);
        const result = await response.json(); // Parse as JSON

        const message = result.message === 'Not Validated' 
            ? "Warning, approve collab to unlock the creators offer"
            : "Collab approved";
        
        setApiMessages(prev => [...prev, message]);
    } catch (error) {
        console.error('Error fetching brand approval validation:', error);
    }
};


const handleApproveCollab = () => {
  fetch(`https://collabbayadmin.com/APIControllers/AdminApproveCollab.php?CollabID=${CollabID}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(data => {
      if (data.message === "Collab updated successfully.") {
        setApproveModalMessage('Booking approved successfully. The creator has been notified.');
        setIsApproveSuccessful(true);
      } else {
        setApproveModalMessage(data.message || 'Failed to approve the collaboration.');
        setIsApproveSuccessful(false);
      }
      setIsApproveModalVisible(true); // Show the modal
    })
    .catch(error => {
      console.error('Error:', error);
      setApproveModalMessage('An error occurred while approving the collaboration. Please try again.');
      setIsApproveSuccessful(false);
      setIsApproveModalVisible(true); // Show the modal
    });
};



const checkRedeemStatus = () => {
  fetch(`https://collabbayadmin.com/APIControllers/CheckCollabRedeemedStatus.php?CollabID=${CollabID}`)
    .then(response => response.json())
    .then(data => {
      if (data.message === "Redeem Found") {
        setIsRedeemed(true);
        setRedeemDateTime(data.dateTime); // Store the DateTime
      }
    })
    .catch(error => console.error('Error fetching redeem status:', error));
};

const renderRedeemStatus = () => {
  if (isRedeemed) {
    return (
      <View style={styles.redeemedContainer}>
        <Text style={styles.redeemedText}>Redeemed on {redeemDateTime}</Text> {/* Display the DateTime */}
      </View>
    );
  }
  return null;
};

  useEffect(() => {
    calculateRedeemableValues();
  }, [ipCount, tierData]);

  const calculateRedeemableValues = () => {
    if (ipCount !== null && tierData !== null) {
      if (ipCount >= 0 && ipCount < tierData.TierOneTarget) {
        setRedeemableTier('None');
        setRedeemableValue(0);
        setRedeemableOffer(0);
      } else if (ipCount >= tierData.TierOneTarget && ipCount < tierData.TierTwoTarget) {
        setRedeemableTier('1');
        setRedeemableValue(tierData.TierOneTarget);
        setRedeemableOffer(tierData.TierOneOffer);
      } else if (ipCount >= tierData.TierTwoTarget && ipCount < tierData.TierThreeTarget) {
        setRedeemableTier('2');
        setRedeemableValue(tierData.TierTwoTarget);
        setRedeemableOffer(tierData.TierTwoOffer);
      } else if (ipCount >= tierData.TierThreeTarget) {  
        setRedeemableTier('3');
        setRedeemableValue(tierData.TierThreeTarget);
        setRedeemableOffer(tierData.TierThreeOffer);
      }
    }
  };

  const handleCopyURL = () => {
    Clipboard.setString(`https://collabbay.com/collab/${CollabID}`);
    alert('Collab link copied to clipboard');
  };

  const handleQRCodePress = () => {
    Linking.openURL('https://collabbayadmin.com/QR.html');
  };

  const handleBackPress = () => {
    navigation.goBack();
  };


  const renderDatePicker = () => {
    const dates = [];
    const today = new Date();


    for (let i = 0; i < 30; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      dates.push(date.toDateString());
    }

    return (
      <View style={styles.datePickerContainer}>
        <Text style={styles.datePickerLabel}>Move Booking Date</Text>
        <ScrollView horizontal>
          {dates.map((date, index) => (
            <TouchableOpacity
              key={index}
              style={[styles.dateOption, { backgroundColor: selectedDate === date ? '#FDF05D' : 'white' }]}
              onPress={() => setSelectedDate(date)}
            >
              <Text style={styles.dateOptionText}>{date}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    );
  };


  const renderTimePicker = () => {
    return (
      <View style={styles.timePickerContainer}>
        <Text style={styles.timePickerLabel}>Select New Time</Text>
        <ScrollView style={styles.timeScrollView}>
          {Array.from({ length: 40 }, (_, i) => {
            const hour = Math.floor((i + 14) / 2) % 24; // Starts at 7:00 AM
            const minute = (i % 2) === 0 ? '00' : '30';
            const ampm = hour >= 12 ? 'PM' : 'AM';
            const adjustedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert hour to 12-hour format
            const timeLabel = `${adjustedHour}:${minute} ${ampm}`;
  
            return (
              <TouchableOpacity
                key={i}
                style={[
                  styles.timeSlot,
                  selectedTime === timeLabel && styles.selectedTimeSlot,
                ]}
                onPress={() => setSelectedTime(timeLabel)}
              >
                <Text>{timeLabel}</Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    );
  };



  const handleSubmit = () => {
    const fullDateTime = `${selectedDate} ${selectedTime}`;
  
    fetch(`https://collabbayadmin.com/APIControllers/AdminUpdateCollab.php?CollabID=${CollabID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Message: message,
        CreditAvailableStart: fullDateTime,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setUpdateModalMessage('Update request sent successfully. The creator has been notified.');
          setIsUpdateSuccessful(true);
        } else {
          setUpdateModalMessage('Error: Unable to update the booking. Please try again.');
          setIsUpdateSuccessful(false);
        }
        setIsUpdateModalVisible(true); // Show the modal
      })
      .catch(error => {
        console.error('Error:', error);
        setUpdateModalMessage('An unexpected error occurred. Please try again.');
        setIsUpdateSuccessful(false);
        setIsUpdateModalVisible(true); // Show the modal
      });
  };
  
  

  const renderMediaContent = () => {
    if (mediaContent.length === 0) return null; // Show nothing if no content exists
  
    return (
      <View style={styles.mediaContentSection}>
        {mediaContent.map((item, index) => (
          <View key={index} style={styles.mediaItemContainer}>
            {/* Display Image */}
            <Image
              style={styles.mediaImage}
              source={{ uri: item.Image }}
              resizeMode="cover"
            />
  
            <View style={styles.mediaContentTextSection}>
              {/* Clickable Text to View Content */}
              <TouchableOpacity onPress={() => handleMediaItemClick(item.PostURL)} style={styles.linkButton}>
                <Ionicons name="link" size={20} color="black" style={styles.linkIcon} />
                <Text style={styles.mediaLinkText}>Click to view content</Text>
              </TouchableOpacity>
  
              {/* Message */}
              <Text style={styles.mediaMessageText}>
                <Ionicons name="chatbubble-ellipses" size={20} color="black" /> {item.Message}
              </Text>
  
              {/* Download Button */}
              <TouchableOpacity onPress={() => handleMediaDownload(item.Image)} style={styles.downloadButton}>
                <Ionicons name="download-outline" size={24} color="black" />
                <Text style={styles.downloadText}>Download</Text>
              </TouchableOpacity>
            </View>
          </View>
        ))}
      </View>
    );
  };
  
  const handleMediaItemClick = (url) => {
    const formattedURL = url.startsWith('http') ? url : `https://${url}`;
    Linking.openURL(formattedURL).catch((err) => console.error('Failed to open URL:', err));
  };
  
  const handleMediaDownload = (url) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.substring(url.lastIndexOf('/') + 1) || 'media-content'; // Extract filename from URL
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading media:', error);
      alert('An error occurred while downloading the media.');
    }
  };
  

  const renderPromoterProfile = () => {
    const handlePress = () => {
      const url = `https://www.instagram.com/${platformUsername}`;
      Linking.openURL(url).catch((err) => console.error("Failed to open URL:", err));
    };
  
    return (
      <View style={styles.profileCard}>
        <Image
          style={styles.profileImage}
          source={{ uri: profileImage }}
        />
        <Text style={styles.profileUsername}>{platformUsername}</Text>
        <Text style={styles.profileFollowers}>{platformFollowers} Followers</Text>
        <TouchableOpacity onPress={handlePress}>
          <Ionicons name="link" size={24} color="black" />
        </TouchableOpacity>
      </View>
    );
  };
  

  
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
    
    <SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
      <View style={styles.backButtonContainer}>
          <TouchableOpacity onPress={handleBackPress}>
            <Ionicons name="arrow-back" size={24} color="black" />
          </TouchableOpacity>
        </View>


        <Modal
  animationType="fade"
  transparent={true}
  visible={isUpdateModalVisible}
  onRequestClose={() => setIsUpdateModalVisible(false)}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <Ionicons
        name={isUpdateSuccessful ? "checkmark-circle-outline" : "alert-circle-outline"}
        size={48}
        color={isUpdateSuccessful ? "green" : "red"}
      />
      <Text style={styles.modalText}>{updateModalMessage}</Text>
      <TouchableOpacity
        style={styles.modalButton}
        onPress={() => {
          setIsUpdateModalVisible(false); // Close the modal
          if (isUpdateSuccessful) {
            window.location.reload(); // Refresh the page after success
          }
        }}
      >
        <Text style={styles.modalButtonText}>Close</Text>
      </TouchableOpacity>
    </View>
  </View>
</Modal>


        <View style={[styles.statusBadge, getStatusStyle(getCollabStatus(latestUpdate))]}>
  <Text style={styles.statusText}>{getCollabStatus(latestUpdate)}</Text>
</View>


        <View style={{
      backgroundColor: '#f2f2ee',
      borderRadius: 10,
      padding: 20,
      shadowOpacity: 0.1,
      shadowOffset: { width: 0, height: 2 },
      shadowRadius: 5,
      elevation: 3,
      marginBottom: 20,
      width: '90%',
      alignSelf: 'center'
    }}>

<TouchableOpacity onPress={toggleHowToCollabVisibility} activeOpacity={0.8}>
  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
    <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>
      How to collab <Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>•</Text>
    </Text>
    <Ionicons name={isHowToCollabVisible ? "chevron-up" : "chevron-down"} size={24} color="black" />
  </View>
</TouchableOpacity>


{isHowToCollabVisible && (
  <>
    {/* Tab Selector */}
    <View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
      <TouchableOpacity
        style={{
          flex: 1,
          paddingVertical: 10,
          borderBottomWidth: 2,
          borderColor: activeTab === 'CodeSystem' ? 'black' : '#ccc',
          alignItems: 'center',
        }}
        onPress={() => setActiveTab('CodeSystem')}
      >
        <Text style={{ color: activeTab === 'CodeSystem' ? 'black' : '#777', fontWeight: 'bold' }}>
          Using a code
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          flex: 1,
          paddingVertical: 10,
          borderBottomWidth: 2,
          borderColor: activeTab === 'QRSystem' ? 'black' : '#ccc',
          alignItems: 'center',
        }}
        onPress={() => setActiveTab('QRSystem')}
      >
        <Text style={{ color: activeTab === 'QRSystem' ? 'black' : '#777', fontWeight: 'bold' }}>
          Using QRs
        </Text>
      </TouchableOpacity>
    </View>

    {/* CodeSystem Instructions */}
    {activeTab === 'CodeSystem' && (
      <View style={{ marginTop: 10 }}>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>1-</Text> The influencer arrives, mentioning they are with collabbay.
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>2-</Text> Ensure staff provide the venue code to the influencer when asked, yours is: 
          <Text style={{ fontWeight: 'bold', color: '#000' }}>{userData.VenueCode || 'N/A'}</Text>.
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>3-</Text> The influencer prepares footage for their content on-site.
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>4-</Text> The final content is due within the agreed deadline after their visit.
        </Text>
      </View>
    )}

    {/* QRSystem Instructions */}
    {activeTab === 'QRSystem' && (
      <View style={{ marginTop: 10 }}>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>1-</Text> The influencer arrives, mentioning they are with collabbay.
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>2-</Text> Provide the offer agreed for the collab.
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>3-</Text> The influencer prepares footage for their content on-site.
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>4-</Text> Before the influencer leaves, scan their QR code.
        </Text>
        <Text style={{ fontSize: 14, color: '#555', marginVertical: 5, lineHeight: 22 }}>
          <Text style={{ fontWeight: 'bold', color: '#000' }}>5-</Text> The final content is due within the agreed deadline after their visit.
        </Text>
      </View>
    )}
  </>
)}
    </View>



        {renderPromoterProfile()} 

   

        <Modal
  animationType="fade"
  transparent={true}
  visible={isApproveModalVisible}
  onRequestClose={() => setIsApproveModalVisible(false)}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <Ionicons
        name={isApproveSuccessful ? "checkmark-circle-outline" : "alert-circle-outline"}
        size={48}
        color={isApproveSuccessful ? "green" : "red"}
      />
      <Text style={styles.modalText}>{approveModalMessage}</Text>
      <TouchableOpacity
        style={styles.modalButton}
        onPress={() => {
          setIsApproveModalVisible(false); // Close the modal
          if (isApproveSuccessful) {
            window.location.href = 'https://collabbayadmin.com/'; // Redirect after success
          }
        }}
      >
        <Text style={styles.modalButtonText}>Close</Text>
      </TouchableOpacity>
    </View>
  </View>
</Modal>



{latestUpdate.Status === 'Pending' &&
  latestUpdate.LastUpdateFrom !== userData.Username && (
    <View style={styles.approveButtonContainer}>
      <TouchableOpacity style={styles.approveButton} onPress={handleApproveCollab}>
        <View style={{ alignItems: 'center' }}>
          {/* Approve Collab Row */}
          <Text style={[styles.approveButtonText, { fontWeight: 'bold', textAlign: 'center' }]}>
            <Ionicons name="checkmark-circle" size={20} color="black" /> Approve Collab
          </Text>

          {/* Notification Row */}
          {accountAdmin && (
  <View style={{ marginTop: 8 }}>
    {accountAdmin.split(',').map((admin, index) => (
      <Text
        key={index}
        style={{
          fontSize: 9,
          color: 'black',
          textAlign: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Ionicons name="mail-outline" size={10} color="black" /> {admin.trim()}
      </Text>
    ))}
  </View>
)}
        </View>
      </TouchableOpacity>
    </View>
  )}


   <TouchableOpacity
  style={styles.influencerProfileButton}
  onPress={() => navigation.navigate('InfluencerProfileAnalysis', { PlatformUsername: platformUsername })}  // Pass dynamically fetched username
>
  <Text style={styles.influencerProfileButtonText}>
    View Influencer Profile 
  </Text>
  <Ionicons name="arrow-forward" size={20} color="black" />
</TouchableOpacity>


<View style={styles.contactCreatorCard}>
  <TouchableOpacity
    style={styles.contactCreatorButton}
    onPress={() => {
      if (promoterEmail) {
        Linking.canOpenURL(`mailto:${promoterEmail}`)
          .then((supported) => {
            if (supported) {
              Linking.openURL(`mailto:${promoterEmail}`);
            } else {
              alert('Email service is not available on this device. Contact the creator via social media.');
            }
          })
          .catch((error) => console.error('Error opening mail client:', error));
      } else {
        alert('No email address available.');
      }
    }}
  >
    <Ionicons name="mail-outline" size={24} color="black" style={styles.contactIcon} />
    <Text style={styles.contactButtonText}>Contact Creator</Text>
  </TouchableOpacity>

  {/* Copy Email Button */}
  <TouchableOpacity style={styles.copyEmailButton} onPress={handleCopyEmail}>
    <Ionicons
      name={isEmailCopied ? 'checkmark-outline' : 'copy-outline'}
      size={20}
      color="black"
      style={styles.copyEmailIcon}
    />
  </TouchableOpacity>
</View>



<View style={styles.statusCard}>
  <Text style={styles.subheader}>Collab Status</Text>
  <Text style={styles.statusLabel}>Message: {latestUpdate.Message}</Text>
  <Text style={styles.statusLabel}>Proposed Booking Date: {latestUpdate.CreditAvailableStart}</Text>
  <Text style={styles.statusLabel}>
  Status: {latestUpdate.Status === 'Pending' ? 'Booking not yet confirmed. Pending approval.' : latestUpdate.Status}
</Text>

{latestUpdate.CreatorFirstName ? (
  <>
    <Text style={styles.subheader}>Creator Details</Text> 
    <Text style={styles.statusLabel}>First Name: {latestUpdate.CreatorFirstName}</Text>
  </>
) : null}


{latestUpdate.CreatorLastName ? (
  <Text style={styles.statusLabel}>Last Name: {latestUpdate.CreatorLastName}</Text>
) : null}

{latestUpdate.CreatorPhoneNumber ? (
  <Text style={styles.statusLabel}>Mobile: {latestUpdate.CreatorPhoneNumber}</Text>
) : null}




<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
  <Text style={styles.statusLabel}>
    Added Visitors (Plus-ones?): {latestUpdate.AddedVisitors}
  </Text>
  <TouchableOpacity onPress={() => setIsCancelModalVisible(true)}>
    <Ionicons name="help-circle-outline" size={20} color="darkgrey" style={{ marginLeft: 5 }} />
  </TouchableOpacity>
</View>


{isCancelModalVisible && (
  <View
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <View
      style={{
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 8,
        alignItems: 'center',
        width: '80%',
      }}
    >
      <Text style={{ fontSize: 16, textAlign: 'center', marginBottom: 15 }}>
        Via Collabbay, the influencer can access this offer. Their plus-ones are managed at your discretion.
      </Text>
      <TouchableOpacity
        onPress={() => setIsCancelModalVisible(false)}
        style={{
          backgroundColor: 'black',
          paddingVertical: 10,
          paddingHorizontal: 20,
          borderRadius: 5,
        }}
      >
        <Text style={{ color: 'white', fontSize: 16 }}>Close</Text>
      </TouchableOpacity>
    </View>
  </View>
)}







<TouchableOpacity style={styles.header} onPress={handleToggleExpand}>
<Text
  style={{
    fontSize: 16, // From existing styles.updateSubheader
    fontWeight: 'bold', // From existing styles.updateSubheader
    color: 'black', // From existing styles.updateSubheader
    marginVertical: 10, // From existing styles.updateSubheader
    textAlign: 'center', // From existing styles.updateSubheader
    backgroundColor: '#e9e9e9', // Slightly darker than #f3f3f3
    borderRadius: 8, // Rounded corners
    padding: 5, // Padding
    paddingBottom: 7,
  }}
>
  Move or cancel booking?{' '}
  <Ionicons name={isExpanded ? 'chevron-up' : 'chevron-down'} size={24} color="black" />
</Text>
</TouchableOpacity>

{isExpanded && (
  <View style={[styles.updateCollabCard, styles.centeredContainer]}>
    <Text style={styles.scanCTALabel}>

    </Text>
    {renderDatePicker()}
    {renderTimePicker()}
    <TextInput
                style={styles.textInput}
                placeholder="Add a message..."
                placeholderTextColor="gray"
                value={message}
                onChangeText={setMessage}
                multiline={true}
                maxLength={400} // Updated character limit
            />
            <Text style={styles.charCounter}>
                {message.length} / 400
            </Text>
    <TouchableOpacity style={styles.button} onPress={handleSubmit}>
      <Text style={styles.buttonText}>Propose Update</Text>
    </TouchableOpacity>

    <TouchableOpacity
      style={[styles.button, { backgroundColor: 'white' }]}
      onPress={toggleCancelModal}
    >
      <Ionicons name="warning-outline" size={20} color="red" />
      <Text style={{ color: 'red', textAlign: 'center' }}>Need to Cancel?</Text>
    </TouchableOpacity>

    {isCancelModalVisible && (
      <View style={styles.cancelModal}>
        <View style={styles.cancelModalContent}>
          <TouchableOpacity style={styles.closeButton} onPress={toggleCancelModal}>
            <Ionicons name="close-circle-outline" size={24} color="black" />
          </TouchableOpacity>
          <Text style={styles.cancelModalTitle}>Are you sure you want to cancel?</Text>
          <Text style={styles.cancelModalSubtitle}>Add a message (required):</Text>
          <TextInput
                style={styles.textInput}
                placeholder="Enter a message..."
                placeholderTextColor="gray"
                value={cancelMessage}
                onChangeText={setCancelMessage}
                multiline={true}
                maxLength={400} // Set the character limit
            />
            <Text style={styles.charCounter}>
                {cancelMessage.length} / 400
            </Text>

          <View style={styles.cancelModalButtons}>
            <TouchableOpacity style={{ padding: 10 }} onPress={handleCancelBooking}>
              <Text
                style={{
                  color: 'black',
                  fontSize: 14,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Yes, Cancel Booking
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                backgroundColor: '#FDF05D',
                padding: 10,
                borderRadius: 20,
              }}
              onPress={toggleCancelModal}
            >
              <Text
                style={{
                  color: 'black',
                  fontSize: 14,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                No, Go Back
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )}
  </View>
)}

</View>



<Modal
  animationType="fade"
  transparent={true}
  visible={isCancelSuccessModalVisible}
  onRequestClose={() => setIsCancelSuccessModalVisible(false)}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalContent}>
      <Ionicons
        name="checkmark-circle-outline"
        size={48}
        color="green"
      />
      <Text style={styles.modalText}>{cancelSuccessMessage}</Text>
      <TouchableOpacity
  style={styles.modalButton}
  onPress={() => {
    setIsCancelSuccessModalVisible(false);
    window.location.reload(); // Refresh the page after closing the modal
  }}
>
  <Text style={styles.modalButtonText}>Close</Text>
</TouchableOpacity>
    </View>
  </View>
</Modal>



<TouchableOpacity
  style={{
    backgroundColor: 'black',
    borderRadius: 15,
    paddingVertical: 12,
    paddingHorizontal: 20,
    width: '70%',
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    
  }}
  onPress={handleCopyURL}
>
  <Text style={{ fontSize: 16, fontWeight: 'bold', color: 'white', marginRight: 8 }}>
    View Collab Link
  </Text>
  <Ionicons name="copy-outline" size={16} color="white" />
</TouchableOpacity>


        {/* <TouchableOpacity style={styles.qrCodeContainer} onPress={handleQRCodePress}>
          <Ionicons name="scan" size={200} color="black" />
        </TouchableOpacity> */}

        {renderRedeemStatus()}

        {/* <Text style={styles.scanCTALabel}>Scan Promoters QR to Redeem Collab Credits</Text> */}
   {/* <Text style={styles.scanCTALabel}>Contact Creator: {promoterEmail ? promoterEmail : ' '}</Text> */}

        <View style={styles.apiMessagesContainer}>
      {apiMessages.map((msg, index) => (
        <View
          key={index}
          style={[
            styles.apiMessage,
            { backgroundColor: msg.includes('Warning') ? '#EC4646' : '#74dc8a' }
          ]}
        >
          <Ionicons
            name={msg.includes('Warning') ? 'alert-circle' : 'checkmark-circle'}
            size={24}
            color="white"
            style={styles.icon}
          />
          <Text style={styles.apiMessageText}>{msg}</Text>
        </View>
      ))}
    </View>







        {renderMediaContent()}

      </ScrollView>
    </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'white',
    width: '95%',
    alignSelf: 'center', // Centers horizontally
    justifyContent: 'center', // Centers vertically
  },
  
  influencerProfileButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
    borderRadius: 20,
    paddingVertical: 12,
    marginVertical: 16,
    paddingHorizontal: 20,
    width: '60%',
    alignSelf: 'center',
  },
  influencerProfileButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
    marginRight: 8,
  },  
  contentContainer: {
    paddingBottom: 32,
    paddingTop: 55,
  },
  backButtonContainer: {
    marginBottom: 16,
    padding: 16,
  },
  header: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: 'center',
  },
  creditsHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: 'center',
  },
  qrCodeContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  label: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
  },
  datePickerContainer: {
    marginBottom: 20,
  },
  datePickerLabel: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: '4',
  },
  dateOption: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginRight: 8,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
  },
  dateOptionText: {
    fontSize: 16,
    color: 'black',
  },
  timePickerContainer: {
    marginBottom: 16,
  },
  timePickerLabel: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  pickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  pickerLabel: {
    fontSize: 16,
    marginRight: 8,
    color: 'black',
  },
  picker: {
    flex: 1,
    color: 'black',
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
    fontSize: 16,
    color: 'black',
  },
  scanCTALabel: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center',
  },
  statusCard: {
    backgroundColor: '#f3f3f3',
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    borderRadius: 20,
  }, 
  subheader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
  },
  updateSubheader: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
    textAlign: 'center',
    marginLeft: 7,
    padding: 2,
     },
  copyCollabButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    paddingVertical: 12,
    marginBottom: 16,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderRadius: 20,
    paddingVertical: 12,
    marginBottom: 16,

  },
  buttonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  copyButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  statusLabel: {
    fontSize: 18,
    marginBottom: 8,
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 20,
    color: 'black',
  },
  mediaContentSection: {
    marginTop: 16,
    paddingHorizontal: 8,
  },
  mediaContentHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: 'black',
    textAlign: 'center',
  },
  mediaItemContainer: {
    marginBottom: 24,
    borderRadius: 20,
    backgroundColor: '#FDF05D',
    padding: 16,
  },
  mediaImage: {
    width: '100%',
    height: 500,
    borderRadius: 20,
    marginBottom: 16,
  },
  mediaContentTextSection: {
    padding: 16,
    borderRadius: 20,
    backgroundColor: 'white',
    textAlign: 'center',
  },
  mediaContentTextSection: {
    padding: 16,
    borderRadius: 20,
    backgroundColor: '#F0F0F0',
    textAlign: 'center',
  },
  mediaPlatformText: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
    textAlign: 'center',
  },
  mediaURLText: {
    fontSize: 16,
    color: 'blue',
    textDecorationLine: 'underline',
    marginBottom: 8,
    textAlign: 'center',
  },
  mediaMessageText: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  apiMessagesContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  apiMessage: {
    borderRadius: 15,
    padding: 15,
    marginVertical: 5,
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  apiMessageText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
  },
  icon: {
    marginRight: 10,
  },
  approveButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#74dc8a',
    borderRadius: 20,
    paddingVertical: 12,
    marginTop: 16,
    marginBottom: 7,
    width: '50%',
    flexDirection: 'row',
  },
  approveButtonContainer: {
  flex: 1,
  justifyContent: 'center', // Centers the button vertically within the container
  alignItems: 'center',      // Centers the button horizontally within the container
},
  approveButtonText: {
    color: 'black',
  },
  profileCard: {
    backgroundColor: '#F1Fs1F1',
    padding: 16,
    marginVertical: 16,
    alignItems: 'center',
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 8,
  },
  profileUsername: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
    marginBottom: 4,
  },
  profileFollowers: {
    fontSize: 16,
    color: 'gray',
  },
  redeemedStatusCard: {
    backgroundColor: '#f8f8f8',
    borderRadius: 8,
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
  },
  redeemedStatusHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
  },
  redeemedStatusItem: {
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  redeemContainer: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  redeemedContainer: {

    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  redeemedText: {
    fontSize: 16,
    color: '#000',
    textAlign: 'center',
  },
  timeScrollView: {
    height: 120, // Adjust height as needed
  },
  timeSlot: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#eee',
  },
  selectedTimeSlot: {
    backgroundColor: '#FDF05D',
  },
  cancelModal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelModalContent: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '90%',
    alignItems: 'center',
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    elevation: 5,
  },
  cancelModalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
    textAlign: 'center',
  },
  cancelModalSubtitle: {
    fontSize: 14,
    marginBottom: 10,
    color: '#666',
    textAlign: 'center',
  },
  cancelModalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 16,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  plusOneLabel: {
    fontSize: 12,
    marginBottom: 5,
    marginTop: 5,
    textAlign: 'center',
  },
  updateCollabCard: {
    backgroundColor: '#fff',
    borderRadius: 15,
    padding: 16,
    marginVertical: 16,
    width: '90%', // Center align and consistent width
    alignSelf: 'center', // Center card horizontally
  },
  centeredContainer: {
    width: '90%',
    alignSelf: 'center',
  },
  
expiredStatus: {
  color: 'black',
  backgroundColor: 'grey',
  paddingHorizontal: 10,
  paddingVertical: 5,
  borderRadius: 5,
},
redeemedStatus: {
  color: 'white',
  backgroundColor: 'green',
  paddingHorizontal: 10,
  paddingVertical: 5,
  borderRadius: 5,
},
upcomingStatus: {
  color: 'black',
  backgroundColor: 'lightgreen',
  paddingHorizontal: 10,
  paddingVertical: 5,
  borderRadius: 5,
},
pendingStatus: {
  color: 'black',
  backgroundColor: 'yellow',
  paddingHorizontal: 10,
  paddingVertical: 5,
  borderRadius: 5,
},
cancelledStatus: {
  backgroundColor: 'red',
  paddingHorizontal: 10,
  color: 'white',
  paddingVertical: 5,
  borderRadius: 5,
},
statusBadge: {
  position: 'absolute',
  top: 16,
  right: 23,
  borderRadius: 15,
  padding: 10,
},
statusText: {
  color: 'black',
  fontWeight: 'bold',
  fontSize: 17,
},
modalContainer: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
},
modalContent: {
  width: '80%',
  padding: 20,
  backgroundColor: 'white',
  borderRadius: 10,
  alignItems: 'center',
  shadowColor: '#000',
  shadowOpacity: 0.2,
  shadowOffset: { width: 0, height: 2 },
  shadowRadius: 5,
  elevation: 5,
},
modalText: {
  fontSize: 18,
  fontWeight: 'bold',
  marginVertical: 10,
  textAlign: 'center',
  color: '#333',
},
modalButton: {
  marginTop: 15,
  backgroundColor: '#FDF05D',
  padding: 10,
  borderRadius: 5,
  width: '80%',
  alignItems: 'center',
},
modalButtonText: {
  fontSize: 16,
  fontWeight: 'bold',
  color: 'black',
},
mediaLinkText: {
  fontSize: 16,
  color: 'black',
  textAlign: 'center',
  marginVertical: 5,
},
downloadText: {
  fontSize: 14,
  color: 'black',
  textAlign: 'center',
  marginTop: 5,
},
downloadIcon: {
  marginTop: 10,
  alignSelf: 'center',
},
mediaContentSection: {
  marginTop: 16,
  paddingHorizontal: 8,
},
mediaItemContainer: {
  marginBottom: 24,
  borderRadius: 20,
  backgroundColor: 'white', // Updated to white background
  padding: 16,
  elevation: 2,
  shadowColor: 'black',
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 0.1,
  shadowRadius: 5,
},
mediaImage: {
  width: '100%',
  height: 400,
  borderRadius: 20,
  marginBottom: 16,
},
mediaContentTextSection: {
  padding: 16,
  borderRadius: 20,
  backgroundColor: '#f3f3f3', // Rounded grey background
  textAlign: 'center',
},
linkButton: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
},
linkIcon: {
  marginRight: 5,
},
mediaLinkText: {
  fontSize: 16,
  color: 'black',
  fontWeight: 'bold', // Bold text for the link
},
downloadButton: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 12,
},
downloadText: {
  fontSize: 14,
  fontWeight: 'bold',
  color: 'black',
  marginLeft: 5,
},
charCounter: {
  marginTop: 3,
  marginBottom: 15,
  fontSize: 14,
  color: 'gray',
  textAlign: 'right',
},
contactCreatorCard: {
  flexDirection: 'row',          // Horizontal layout
  alignItems: 'center',          // Align items vertically in the centre
  justifyContent: 'space-between', // Space between buttons
  padding: 10,
  marginVertical: 16,
  borderRadius: 12,
  backgroundColor: '#fff',
  width: '90%',
  alignSelf: 'center',
  borderWidth: 1,
  borderColor: '#fff',
},

contactCreatorButton: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  paddingVertical: 10,
  paddingHorizontal: 15,
  borderRadius: 12,
  flex: 1,                     // Allow the button to take up remaining space
  borderWidth: 1,
  borderColor: '#f3f3f3',
},

copyEmailButton: {
  backgroundColor: '#f3f3f3',
  borderRadius: 12,
  padding: 10,
  marginLeft: 8,
  width: 45,
  height: 45,
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 1,
  borderColor: '#f3f3f3',
},

contactIcon: {
  marginRight: 8,
},

contactButtonText: {
  fontSize: 14,
  fontWeight: 'bold',
  color: 'black',
},
copyEmailIcon: {
  alignSelf: 'center',
},


});

;
  
export default CollabCampaignDetails;

